import axios from 'axios';
import React, { useState, useRef } from "react";
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup'
import ReCAPTCHA from "react-google-recaptcha";

import { instagramUrl, twitterUrl, linkedInUrl, facebookUrl } from "../data/externalUrls";
import { Base, LimitMaxWidth, SectionHeading } from "../components/layout";
import { SelectField, TextField, hearAboutUsOptions } from "../components/forms";
import HeroNoCtaSection from "../components/page-sections/HeroNoCtaSection";
import SEO from '../components/seo';
import LocationIcon from "../assets/svg/icon-location";
import WorkingHoursIcon from "../assets/svg/icon-working-hours";
import GetInTouchIcon from "../assets/svg/icon-get-in-touch";
import InstagramWhiteIcon from "../assets/svg/icon-instagram-white";
import TwitterWhiteIcon from "../assets/svg/icon-twitter-white";
import LinkedInWhiteIcon from "../assets/svg/icon-linkedin-white";
import FacebookWhiteIcon from "../assets/svg/icon-facebook-white";
import heroPng from "../assets/images/hero-contact-us.png";
import heroAvif from "../assets/images/hero-contact-us.avif";
import heroWebp from "../assets/images/hero-contact-us.webp";

export const Head = () => <SEO title="Syrona Health | Contact Us" />

const ContactUsPage: React.FC = () => <Base><ContactUsContent /></Base>

export default ContactUsPage;

const ContactUsContent: React.FC = () => <>
  <HeroNoCtaSection 
    image={heroWebp}
    // image={heroPng}
    heading={<>Get in Touch!</>}
    text="Please contact us with any questions via email or thorugh our contact form"
    bodyNodes={[<ContactDetails />]}
  />
  <ContactFormSection />
</>

const ukAddress = [
  "Syrona Health",
  "Collingwood Buildings",
  "38 Collingwood Street",
  "Newcastle Upon Tyne",
  "NE1 1JF",
  "UK",
]
const usAddress = [
  "Syrona Health",
  "99 Hudson Street, 5th Floor",
  "New York, NY 10013",
  "USA",
]
const ContactDetails = () => <>
  <div className="mt-8 flex flex-col space-y-4">
    <ContactDetail icon={<LocationIcon />} title={"Office"} detail={<>{ukAddress.map(l => <p>{l}</p>)}</>} />
    <ContactDetail icon={<LocationIcon />} title={"Office"} detail={<>{usAddress.map(l => <p>{l}</p>)}</>} />
    <ContactDetail icon={<GetInTouchIcon />} title={"Get in Touch"} detail={"info@syronahealth.com"} />
  </div>
  <div className="ml-3 mt-12 flex space-x-8">
    <a href={instagramUrl}><InstagramWhiteIcon /></a>
    <a href={twitterUrl}><TwitterWhiteIcon /></a>
    <a href={linkedInUrl}><LinkedInWhiteIcon /></a>
    <a href={facebookUrl}><FacebookWhiteIcon /></a>
  </div>
</>

const ContactDetail = ({icon, title, detail}) => <>
  <div className="flex space-x-4 items-start">
    <div className="m-2 w-12 h-12 flex justify-center items-center">
      {icon}
    </div>
    <div className="flex flex-col">
      <div className="text-s3 font-nunsb text-white">{title}</div>
      <div className="text-b2 font-nun text-white">{detail}</div>
    </div>
  </div>
</>

const ContactFormSection: React.FC = () => {
  const [captchaToken, setCaptchaToken] = useState('')
  const [showCaptchaNudge, setShowCaptchaNudge] = useState(false)
  const [loading, setLoading] = useState(false);
  const [sent, setSent] = useState(false);
  const recaptchaRef = useRef(null);

  const schema = Yup.object().shape({
    name: Yup.string().required("Required"),
    email: Yup.string().email('Must be a valid email').required('Required'),
    company: Yup.string().required('Required'),
    phone: Yup.string(),
    hearAboutUs: Yup.string().required('Required'),
    subject: Yup.string().required("Required"),
    message: Yup.string().required("Required"),
  })

  const onSubmit = (values: {[key: string]: string}) => {
    const submit = async () => {
      if (captchaToken) {
        setLoading(true)
        let data = {
          full_name: values.name,
          email: values.email,
          company_name: values.company,
          ...(values.phone ? {phone_number: values.phone} : {}),
          hear_about_us: values.hearAboutUs,
          subject: values.subject,
          message: values.message,
          context: "Contact us",
          captcha_token: captchaToken,
        }
      await axios.post(
          `${process.env.GATSBY_API_URL}/v1/api/contacts/message/`,
          data
        ).catch(e => {
          console.log(e)
          return
        }).finally(() => {
          setLoading(false)
          setSent(true)
          recaptchaRef.current && recaptchaRef.current.reset();
        })
      } else {
        setShowCaptchaNudge(true)
      }
    }

    submit()
  }

  const onCaptchaSuccess = (captchaToken: string) => {
    setCaptchaToken(captchaToken)
    setShowCaptchaNudge(false)
  }

  return <>
    <div className='my-12'>
      <LimitMaxWidth>
        <SectionHeading>
          Send us an email
        </SectionHeading>
        <div className="text-sb font-nunsb text-black text-center">
          If you have any questions, contact us here and we will respond within 1-3 days
        </div>
        <div className="my-12 flex justify-center">
          <div className="sm:w-8/12 max-sm:w-full">
            <Formik
              initialValues={{
                name: '',
                email: '',
                company: '',
                phone: '',
                hearAboutUs: '',
                subject: '',
                message: '',
              }}
              validationSchema={schema}
              onSubmit={onSubmit}
            >
              {({ errors, touched }) => <>
                <Form>
                  <div className="flex flex-col space-y-8 justify-center items-center">
                      <div className="w-full grid md:grid-cols-2 max-md:grid-cols-1 gap-8">
                        <TextField placeholder="Full Name*" name="name" disabled={sent} />
                        <TextField placeholder="Email*" name="email" disabled={sent} />
                        <TextField placeholder="Company*" name="company" disabled={sent} />
                        <TextField placeholder="Phone number" name="phone" disabled={sent} />
                      </div>
                      <div className='w-full flex flex-col space-y-8'>
                        <SelectField name="hearAboutUs" options={hearAboutUsOptions} placeholder="How did you hear about Syrona Health?*" disabled={sent} />
                        <TextField placeholder="Subject*" name="subject" disabled={sent} />
                      </div>
                      <TextArea placeholder="How can we help?" name="message" disabled={sent} />
                  </div>
                  <div className="flex justify-center">
                    <button className={loading ? styles.buttonLoading : sent ? styles.buttonSent : styles.button} type="submit" disabled={loading||sent}>{loading ? "Sending" : sent ? "Sent!" : "Send"}</button>
                  </div>
                </Form>
              </>}
            </Formik>
            <div className='flex flex-col justify-center items-center'>
              <div className={`${showCaptchaNudge ? "" : "hidden"} text-sm font-nun text-red-400`}>Please complete captcha</div>
              <ReCAPTCHA
                ref={recaptchaRef}
                className="mt-4 rounded-md"
                sitekey={`${process.env.GATSBY_RECAPTCHA_SITE_KEY}`}
                onChange={onCaptchaSuccess}
              />
            </div>
          </div>
        </div>
      </LimitMaxWidth>
    </div>
  </>
}

type TextAreaProps = {
  name: string;
  placeholder: string;
  disabled?: boolean;
}

const TextArea: React.FC<TextAreaProps> = ({ name, placeholder, disabled }) => <>
  <div className="relative w-full">
    <Field name={name}>
      {({
        field,
        meta,
      }) => (
        <div className="relative">
          <textarea className={`${!(meta.error && meta.touched) ? styles.textArea : styles.errorTextArea}`} placeholder={placeholder} name={name} disabled={disabled} {...field} />
          {meta.touched && meta.error && (
            <div className="absolute -bottom-6">
              <div className={styles.errorText}>{meta.error}</div>
            </div>
          )}
        </div>
      )}
    </Field>
  </div>
</>

const styles = {
  textArea: "form-textarea w-full h-64 rounded-md border-solid border-syr-silver", 
  errorTextArea: "form-textarea w-full h-64 rounded-md border-solid border-syr-silver outline outline-red-400", 
  errorText: "text-xs font-nun text-red-400",
  button: "my-8 bg-syr-snd text-btn font-nun text-white px-4 py-2 rounded-md",
  buttonLoading: "my-8 bg-syr-dark-gray text-btn font-nun text-white px-4 py-2 rounded-md",
  buttonSent: "my-8 bg-syr-snd-lt text-btn font-nun text-syr-snd px-4 py-2 rounded-md outline outline-syr-snd",
}
